import { render, staticRenderFns } from "./ForgotpasswordReseller.vue?vue&type=template&id=76ff5210&"
import script from "./ForgotpasswordReseller.vue?vue&type=script&lang=js&"
export * from "./ForgotpasswordReseller.vue?vue&type=script&lang=js&"
import style0 from "./ForgotpasswordReseller.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports