<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="login-form">
    <div class="container">
      <div class="vx-row justify-center">
        <div class="vx-col lg:w-4/5 w-full">
          <div class="login-main-area">
            <div class="vx-row justify-center">
              <div class="vx-col xl:w-1/2 w-full">
                <div class="logo-login">
                  <span class="top-circles"></span>
                  <img
                    class="dashboard-img"
                    src="../../assets/images/visual-dashboard.svg"
                  />
                  <h5>login</h5>
                </div>
              </div>
              <div class="vx-col xl:w-1/2 w-full">
                <div class="login-content">
                  <h1>Forgot Password</h1>
                  <span class="text-danger text-sm"></span>
                  <div class="login-icon">
                    <img src="../../assets/images/login-icon.png" />
                  </div>
                  <form data-vv-scope="loginForm" class="login-form-info">
                    <div class="vx-row">
                      <div class="vx-col w-full">
                        <vs-input
                          name="email"
                          icon-no-border
                          icon="icon icon-user"
                          icon-pack="feather"
                          label-placeholder="Email"
                          v-model="email"
                          v-validate="'required|email'"
                          class="w-full"
                        />
                      </div>
                      <div class="vx-col w-full">
                        <vs-button
                          color="primary"
                          id="user-login"
                          :disabled="button_loading"
                          type="filled"
                          class="w-full float-left mt-6"
                          @click="forgotPassword()"
                          >Email My Password</vs-button
                        >
                      </div>
                    </div>
                  </form>
                  <router-link
                    to="Login"
                    class="float-left w-full mt-5 text-center"
                    ><b>Return to Login</b></router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Validator } from "vee-validate";

const dict = {
  custom: {
    email: {
      required: "Please enter email",
      email: "Please enter valid email",
    },
  },
};

Validator.localize("en", dict);

export default {
  data() {
    return {
      email: null,
    };
  },
  methods: {
    forgotPassword() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.button_loader(true, "#user-login");
          let url =
            "Account/ForgotPasswordVue?Email=" + encodeURIComponent(this.email);
          this.axios
            .post(url)
            .then((response) => {
              // let data = response.data;
              this.button_loader(false, "#user-login");
              //this.email = null;
              this.$vs.notify({
                title: "Success",
                text: response.data,
                color: "success",
                iconPack: "feather",
                icon: "icon-check",
                position: "top-right",
                time: 60000,
              });
            })
            .catch((e) => {
              this.showError(e, "#user-login");
            });
        }
      });
    },
  },
};
</script>

<style lang="scss">
input::-webkit-input-placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}

input:-ms-input-placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}

input::placeholder {
  color: rgba(var(--vs-primary), 1);
  opacity: 1;
}

.login-form {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  min-height: 100vh;
  background: #ffffff;
  z-index: 1;
  padding: 32px 0px;

  .login-content {
    float: left;
    width: calc(100% - 10%);
    margin-left: 10%;
    background: #ffffff;
    box-shadow: 0px 0px 12px -1px rgba(0, 0, 0, 0.15);
    border-radius: 1em;
    padding: 32px 48px;
    position: relative;

    &:after {
      position: absolute;
      left: -100px;
      top: 32px;
      height: 212px;
      width: 212px;
      border-radius: 100%;
      background: rgba(205, 205, 205, 0.15);
      content: "";
      z-index: -1;
    }

    h1 {
      float: left;
      width: 100%;
      font-size: 20px;
      font-weight: 600;
      margin-bottom: 30px;
    }

    .vs-input--label {
      padding-left: 0px;
      font-weight: 500;
      color: #666666;
      margin-bottom: 8px;
      float: left;
      width: 100%;
    }

    .vs-con-input {
      float: left;
      width: 100%;

      .vs-input--input {
        border: 0px !important;
        border-radius: 8px !important;
        background: rgba(var(--vs-primary), 0.1);
        color: rgba(var(--vs-primary), 1);
        font-weight: 500;

        &:focus {
          box-shadow: none;
        }
      }
    }

    .vs-input--icon.icon-after {
      border: 0px;
      font-weight: 600;
      color: rgba(var(--vs-primary), 1);
    }

    .con-slot-label {
      font-size: 12px;
      color: #333;
      font-weight: 500;
      margin-left: 4px;
    }

    .vs-button {
      border-radius: 8px;
      padding: 6px 30px;
    }

    .login-icon {
      background: rgba(var(--vs-primary), 1);
      width: 56px;
      height: 56px;
      border-radius: 100%;
      float: left;
      text-align: center;
      position: absolute;
      left: -30px;
      top: 60px;
      display: flex;
      align-items: center;
      justify-content: center;
      align-content: center;
      box-shadow: 0px 0px 10px 2px rgba(var(--vs-primary), 0.2);

      img {
        max-width: 22px;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .logo-login {
    float: left;
    width: 100%;
    position: relative;
    height: 100%;
    display: flex;
    align-items: center;

    .dashboard-img {
      width: calc(100% - 14%);
      background: #ffffff;
      border-radius: 0.4em;
      float: right;
      margin-left: 14%;
    }

    .top-circles {
      position: absolute;
      left: 35%;
      top: -60px;
      width: 70px;
      height: 70px;
      background: rgba(var(--vs-primary), 0.1);
      content: "";
      border-radius: 100%;

      &:after {
        position: absolute;
        left: -13px;
        bottom: -1px;
        width: 40px;
        height: 40px;
        background: rgba(205, 205, 205, 0.3);
        content: "";
        border-radius: 100%;
      }
    }

    h5 {
      position: absolute;
      right: -25%;
      bottom: -90px;
      font-size: 140px;
      text-transform: capitalize;
      font-weight: 700;
      color: rgba(205, 205, 205, 0.2);

      &:after {
        position: absolute;
        left: 111px;
        top: 76px;
        width: 38px;
        height: 40px;
        background: rgba(var(--vs-primary), 1);
        content: "";
        border-radius: 100%;
        box-shadow: 0px 15px 32px 8px rgba(var(--vs-primary), 0.3);
      }
    }
  }
}

.login-form-info {
  float: left;
  width: 100%;
}

@media (max-width: 1199px) {
  .login-form .logo-login {
    display: none;
  }

  .login-form .login-content {
    margin-left: 100px;
    width: calc(100% - 100px);
  }
}

@media (max-width: 991px) {
}

@media (max-width: 575px) {
  .login-form .login-content {
    padding: 32px;
  }
}

@media (max-width: 480px) {
  .login-form .login-content {
    margin-left: 0px;
    width: calc(100% - 0px);
    margin-top: 32px;
    padding-top: 56px;
  }

  .login-form .login-content .login-icon {
    right: 0px;
    left: 0px;
    top: -30px;
    margin: auto;
  }
}
</style>
